<template>
  <header :class="{'collapsed' : collapsed}">
    <div class="nav-wrapper container">
      <router-link :to="{name: 'home'}" :class="{'disabled' : isHome}" class="logo">
        <img src="/assets/img/joma-bronze.svg">
      </router-link>
      <app-nav v-if="!overlayMode"></app-nav>
      <div v-else class="hamburger" @click="overlay = !overlay" :class="{'active' : overlay}"></div>
    </div>

    <div v-if="overlayMode" class="menu-overlay" :class="{'active' : overlay}">
      <app-nav @close-menu="closeMenu"  :show-home="true"></app-nav>
    </div>

  </header>
</template>


<script>
  import AppNav from "~/components/AppNav.vue";

  export default {
    components : { AppNav },
    data(){
      return {
        collapsed: false,
        overlay: false,
      }
    },
    computed: {
      isHome() {
        return this.$route.name === 'home'
      },
      overlayMode() {
        return this.$is('xs') || this.$is('sm')
      },
      scrollOffset() {
        if(this.overlayMode) {
          return 0
        } else {
          return 60
        }
      }
    },
    methods: {
      checkMenu() {
        this.collapsed = window.scrollY > this.scrollOffset
      },
      closeMenu(value){
        this.overlay = false
      }
    },
    watch: {
      overlayMode(){
        if(!this.overlayMode) {
          this.overlay = false
        }
      }
    },
    created() {
      this.checkMenu()
      window.addEventListener('scroll',this.checkMenu);
    },
    destroyed() {
      window.removeEventListener('scroll',this.checkMenu);
    }
  }
</script>