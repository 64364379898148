<template>
  <nav>
    <!-- use @click.native for any actions outside of router -->
    <router-link v-if="showHome":to="{name: 'home'}" @click.native="$emit('close-menu')">Home</router-link>
    <router-link :to="{name: 'benefits'}" @click.native="$emit('close-menu')">Sleep Benefits</router-link>
    <router-link :to="{name: 'sustainability'}" @click.native="$emit('close-menu')">Sustainability</router-link>
    <router-link :to="{name: 'memory-wool'}" @click.native="$emit('close-menu')">Memory Wool</router-link>
    <router-link :to="{name: 'products'}" @click.native="$emit('close-menu')">Products</router-link>
    <router-link :to="{name: 'story'}" @click.native="$emit('close-menu')">Our Story</router-link>
    <router-link :to="{name: 'contact'}" @click.native="$emit('close-menu')">Contact</router-link>
  </nav>
</template>


<script>
  export default {
    props: {
      showHome: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
  }
</script>