<template>
  <footer ref="footer">    
    <div class="footer-wrapper container grid gap-30">
      <router-link :to="{name: 'home'}" class="logo" v-scroll-to="'header'">
        <img src="/assets/img/joma-full-white.svg">
      </router-link>
      <div class="nav-wrapper grid">
        <app-nav v-if="!$is('xs') && !$is('sm')"></app-nav>
        <div class="submenu">
          <a href="https://oritain.com/partners/joma-wool/" target="_blank">Oritain Verified</a>
          <a href="https://www.oeko-tex.com/en/" target="_blank">OEKO-TEX Certified</a>
          <router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
  import AppNav from "~/components/AppNav.vue";
  import ImageHelper from "~/components/ImageHelper.vue";

  export default {
    components : { AppNav, ImageHelper },
    data(){
      return {
      }
    },
    computed: {
      borderOffset() {
        if(this.$is('xs') || this.$is('sm')) {
          return 0
        } else {
          return 20
        }
      }
    },
    methods: {
      footerOffset(source) {
        this.$store.commit('setFooterOffset', (this.$refs.footer.offsetHeight - this.borderOffset) + 'px')
      },
    },
    mounted() {
      this.footerOffset()
    },
    created() {
      window.addEventListener('resize',this.footerOffset);
    },
    destroyed() {
      window.removeEventListener('resize',this.footerOffset);
    }
  }
</script>