
import Home from './components/pages/Home'
import Benefits from './components/pages/Benefits'
import Sustainability from './components/pages/Sustainability'
import Products from './components/pages/Products'
import MemoryWool from './components/pages/MemoryWool'
import Story from './components/pages/Story'
import Contact from './components/pages/Contact'
import Terms from './components/pages/Terms'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import ErrorPage from './components/pages/Error'


let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/sleep-benefits',
        name: 'benefits',
        component: Benefits,
        meta: { title: 'Sleep Benefits' },
    },
    {
        path: '/sustainability',
        name: 'sustainability',
        component: Sustainability,
        meta: { title: 'Sustainability' },
    },
    {
        path: '/products',
        name: 'products',
        component: Products,
        meta: { title: 'Products' },
    },
    {
        path: '/products/memory-wool',
        name: 'memory-wool',
        component: MemoryWool,
        meta: { title: 'Memory Wool' },
    },
    {
        path: '/our-story',
        name: 'story',
        component: Story,
        meta: { title: 'Our Story' },
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' },
    },
    // {
    //     path: '/terms',
    //     name: 'terms',
    //     component: Terms,
    //     meta: { title: 'Terms and Conditions' },
    // },
    { 
        path: "*", 
        name: 'error',
        component: ErrorPage,
        meta: { title: 'Error!' },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}