<template>
  <div id="products">

    <introduction>
      <template v-slot:title>
        We offer a range of Joma Wool<sup>®</sup> products, all crimped in Christchurch New Zealand
      </template>
      <template v-slot:desc>
        <p>Our innovative crimping process is done at Wool Technologies, our subsidiary factory, for export all around the world. We closely oversee the production of Joma Wool® to ensure that every bale meets our ultra strict quality standards.</p>
        <p>Pricing per bale is available on request. Please contact us to request a sample.</p>
      </template>
    </introduction>



    <module class="is-footer is-pattern">

      <div class="products-wrapper grid" :class="{'container' : !$is('xs')}">
        <div class="product grid" 
             v-for="(product,index) in products" 
             :key="index"
             :class="{'active' : active === index}"
             v-observe-visibility="(isVisible, entry) => collapse(isVisible, entry, index)"
             data-aos="fade-up">

          <div class="inner">
            <div class="title-wrapper grid gap-20 sm-col-1" :style="getTitleHeight">
              <h3 class="charcoal" :ref="index" v-html="product.title"></h3>
              <image-helper v-if="$is('xs')" :src="product.img"></image-helper>
            </div>
           
            <div class="detail-wrapper" :style="getDetailHeight(index)" :ref="'detail-' + index">
              <p class="description">{{product.desc}}</p>
              <p v-for="(metric,index) in product.metrics" :key="index" class="small strong metric" v-html="metric"></p>
              <div class="links">
                <router-link :to="{name:'contact'}" @click.native="scrollTo()">Enquire</router-link>
                <router-link v-if="product.link" :to="{name:product.link}">Learn More</router-link>
              </div>
            </div>
            <p v-if="$is('xs')" @click="setActive(index)" class="small strong read-more" >Read more ></p>
            
          </div>
          <div class="is-cover">
            <image-helper :src="product.img"></image-helper>
          </div>
        </div>
      </div>

    </module>
   
  </div>
</template>

<script>
  import Introduction from "~/components/Introduction.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import Module from "~/components/Module.vue";

  export default {
    components : { Introduction, AccordionItem, Module },

    data() {
      return {
        width: null,
        refsAvailable: false,
        active: null,
        titleHeight: null,
        products: [
          {
            title: 'Joma Wool<sup>®</sup>',
            desc: 'Joma Wool® is our mechanically crimped flagship product known for its high bulk and resilience. Joma Wool® can be used as a loose fill, or as an ingredient for further manufacturing.',
            metrics: [
              '33+cm<sup>3</sup>/kg bulk',
              // 'MOQ 50Kg'
            ],
            img: 'product-loose'
          },
          {
            title: 'Joma Wool<sup>®</sup> Knops',
            desc: 'Joma Wool® Knops are an engineered form of Joma Wool®, specifically designed to be used as a loose fill for interior furnishings.',
            metrics: [
              // 'MOQ 50Kg'
            ],
            img: 'product-knops'
          },
          {
            title: 'Joma Wool<sup>®</sup> Batting',
            desc: 'Joma Wool® Batting is a non-woven, layered, 100% wool batt designed for use in mattresses, duvets and interior textiles. Exotic natural fibres such as cashmere and silk can be added up to 10%wt. ',
            metrics: [
              '250/300/350/500gsm',
              '2.2m wide in 20m rolls',
              // 'MOQ 1 roll'
            ],
            img: 'product-batting'
          },
          {
            title: 'Joma Wool<sup>®</sup> Needled Wool',
            desc: 'Joma Wool® Needled Wool is a densely needled, 100% wool layering created from Joma Wool® Batting. Needling provides dramatically increased resistance to tearing in all directions and is suitable for construction, apparel or interior textiles, and agriculture. ',
            metrics: [
              '250/300/350/500gsm',
              '2.2m wide in 20m rolls',
              // 'MOQ 1 roll'
            ],
            img: 'product-needled'
          },
          {
            title: 'Joma Memory Wool<sup>™</sup>',
            desc: 'Joma Memory Wool™ is needled wool that is processed in such a way that it creates a delayed memory effect. Ideal as a memory foam replacement.',
            metrics: [
              '750gsm',
              '2.4m wide in 20m rolls',
            ],
            img: 'product-memory-wool',
            link: 'memory-wool'
          },
        ]
      }
    },
    computed: {
      getTitleHeight() {
        if(this.refsAvailable && this.$is('xs')) {
          return { 'minHeight' : this.titleHeight + 'px'}
        }
      },
      
    },
    methods: {
      isActive(index) {
        return this.active === index
      },
      setActive(index) {
        if (this.isActive(index) && !this.$is('xs')) {
          this.reset()
        } else {
          this.active = index
        }
      },
      setTitleHeight() {
        if(window.innerWidth !== this.width) {
          this.reset()
          let obj = this.$refs
          let self = this
          this.titleHeight = null

          Object.keys(obj).forEach(function (key) {
            if(obj[key][0].localName === 'h3') {
              if(obj[key][0].clientHeight > self.titleHeight || self.titleHeight === null) {
                self.titleHeight = obj[key][0].clientHeight
              }
            }
          });
        }
      },
      getDetailHeight(index) {
        if(this.refsAvailable && this.isActive(index)) {
          return { 'height' : this.$refs['detail-' + index][0].scrollHeight + 'px'}
        }
      },
      collapse(isVisible, entry, index) {
        if(!isVisible && this.isActive(index)) {
          this.reset()
        }
      },
      reset() {
        this.active = null
      },
      scrollTo() {
        let offset = (this.$is('xs') || this.$is('sm')) ? -60 : -89
        this.$scrollTo('#enquiry',500, { offset: offset })
      }
    },
    mounted() {
      this.refsAvailable = true
      this.setTitleHeight()
    },
    created() {
      this.width = window.innerWidth
      window.addEventListener('resize',this.setTitleHeight);
    },
    destroyed() {
      window.removeEventListener('resize',this.setTitleHeight);
    }
  }
</script>

