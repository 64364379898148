<template>
  <div class="accordion-item">
    <div class="accordion-title" :class="{'active' : active}" @click="toggle">
      <slot name="title"></slot>
    </div>
    <div class="accordion-content" ref="content" :class="{'active' : active}" :style="setHeight">
      <div class="inner">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
        active: false,
        scrollHeight: null,
        width: null
      }
    },
    computed: {
      setHeight(){
        if(this.active && this.scrollHeight) {
          return { height : this.scrollHeight + 'px'}
        }
      },
    },
  	methods: {
      toggle() {
        this.active = !this.active
        this.scrollHeight = this.$refs.content.scrollHeight
      },
      close() {
        if(window.innerWidth !== this.width) {
          this.width = window.innerWidth
          this.active = false
        }
      }
    },
    created() {
      this.width = window.innerWidth
      window.addEventListener('resize',this.close);
    },
    destroyed() {
      window.removeEventListener('resize',this.close);
    }
  }
</script>