<template>
  <div class="s-item">
    <div class="s-title">
      <h3><slot name="title"></slot></h3>
    </div>
    <div class="s-content">
      <div class="grid gap-20">
        <div class="is-cover sixteen-nine">
          <slot name="image"></slot>
        </div>
        <div>
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
  }
</script>