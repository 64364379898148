<template>
  <div class="module">
    <slot></slot>
  </div>
</template>


<script>
  export default {
    data(){
      return {
      }
    }
  }
</script>