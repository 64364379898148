<template>
  <div id="sustainability">
    <introduction>
      <template v-slot:title>
        Good for people, animals and the planet
      </template>
      <template v-slot:desc>
        <p>Ethically produced on New Zealand farms, free from plastics or harmful chemicals, and quality assured at every step, Joma Wool® is the perfect fibre for a healthier future.</p>
      </template>
    </introduction>

    <module class="is-footer is-pattern">

      <div class="diagram">
        <div class="container">
          <div class="grid sm-col-2 gap-20">
            <div class="col">
              <h3>Joma Wool® supports a circular model of production</h3>
            </div>
            <div class="col">
              <p>Unlike synthetic fibres, Joma Wool® fits into a sustainable, circular model of production that minimises waste and pollution.</p>
            </div>
          </div>
          <img :src="$getImageSrc('circular-model')">
        </div>
      </div>

      <div class="container">
        
        <sustainability-item>
          <template v-slot:title>
            Raised on lush New Zealand pastures
          </template>
          <template v-slot:image>
            <image-helper :src="'story'"></image-helper>
          </template>
          <template v-slot:content >
            <p>Joma Wool® is gently shorn from grass-fed sheep, which roam freely across some of New Zealand’s most remote farms. New Zealand sheep are renowned for their strong, white wool which results from a combination of climate, animal care and expert farming practices based on regenerative agriculture.</p>
            <p>“Regen” agriculture helps to reverse climate change by restoring soil’s organic matter and biodiversity, providing more effective carbon sequestration. </p>
            <p>Did you know, wool itself is a natural short-term store of atmospheric carbon? Carbon makes up 50% of wool’s weight, meaning that our sheep are literally carrying the ‘weight of the world’ on their shoulders.</p>
          </template>
        </sustainability-item>


        <sustainability-item>
          <template v-slot:title>
            Our wool is origin certified
          </template>
          <template v-slot:image>
            <image-helper :src="'difference-3'"></image-helper>
          </template>
          <template v-slot:content >
            <p>Joma Wool® is independently verified to be of genuine New Zealand origin by an independent auditor, Oritain.</p>
            <p>How does it work? The experts at Oritain have scientifically analysed samples of Joma Wool® to create an origin “fingerprint” of the wool based on its natural isotopic variance. This means Oritain can test any Joma Wool® product anywhere in the supply chain to ensure that it is indeed genuine Joma Wool® of New Zealand origin.</p>
            <p>We are committed to product integrity and pleased to offer customers the peace of mind that comes with independent verification. </p>
            <p><a href="https://oritain.com/partners/joma-wool/" target="_blank">Learn more at oritain.com</a></p>
            <img class="oritain" src="/assets/img/oritain-black.svg">
          </template>
        </sustainability-item>


        <sustainability-item>
          <template v-slot:title>
            Caring for our animals and environment
          </template>
          <template v-slot:image>
            <image-helper :src="'difference-4'"></image-helper>
          </template>
          <template v-slot:content >
            <p>We genuinely care about the wellbeing of the sheep who provide our wool, as well as the precious natural environment they’re raised in. When we know that when both animals and the environment are happy and healthy, we can rest easy.</p>
            <p>To ensure we’re doing the best for our natural world, we source Joma Wool® from Kiwi farmers who promote healthy ecosystems amongst their grazing pastures and follow the internationally recognised “five freedoms” for animal welfare. </p>
            <p>
              <ul>
                <li>›  Freedom from hunger and thirst</li>
                <li>›  Freedom from discomfort</li>
                <li>›  Freedom from pain, injury or disease</li>
                <li>›  Freedom to express normal behaviour</li>
                <li>›  Freedom from fear and distress</li>
              </ul>
            </p>
          </template>
        </sustainability-item>


        <sustainability-item class="okeo-tex">
          <template v-slot:title>
            Quality assured and free from plastics
          </template>
          <template v-slot:image>
            <inline-svg :src="$getImageSrc('okeo-tex')"></inline-svg>
          </template>
          <template v-slot:content >
            <p>Joma Wool® meets OEKO-TEX® Standard 100 – globally standardised, independent mark of quality for textile products. This certification means that Joma Wool® meets extremely strict limit values for hundreds of harmful substances. Our wool is free from APEO, NPEO, pesticides, carcinogenic colourants and heavy metals.</p>
            <p>You can download our OEKO-TEX® certificate by <a href="/assets/docs/okeo-tex-certificate-2024.pdf" target="_blank">clicking here</a>.</p>
          </template>
        </sustainability-item>

      </div>
    </module>

  </div>
</template>

<script>
  import Introduction from "~/components/Introduction.vue";
  import SustainabilityItem from "~/components/SustainabilityItem.vue";
  import Module from "~/components/Module.vue";

  export default {
    components : { Introduction, SustainabilityItem, Module },

    data() {
      return {
      }
    },
  }
</script>