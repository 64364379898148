<template>
    <div id="hero-image" class="is-cover" :class="{'sml' : $route.name !== 'home'}">
      <parallax :breakpoint="'(min-width: 576px)'">
        <image-helper :src="data.image" :key="$route.name" :position="data.position"></image-helper>
      </parallax>


      <div class="wrapper container">
        <h1 :class="data.color" v-html="data.headline"></h1>
        <router-link v-if="isHome" :to="{name: 'products'}" class="btn">Discover Our Products</router-link>
      </div>
      
    </div>
</template>


<script>
  import Parallax from 'vue-parallaxy'
  
  export default {
    components: { Parallax },
    props: {
      data: {
        type: Object, 
        required: true,
      }
    },
    data(){
      return {
      }
    },
    computed: {
      isHome() {
        return this.$route.name === 'home' 
      }
    }
  }
</script>