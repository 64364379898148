
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
//import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import AOS from 'aos'
	// import 'aos/dist/aos.css';  
	// -- we dont need everything, selected styles in shared.scss
import VueObserveVisibility from 'vue-observe-visibility'
import ImageHelper from './components/ImageHelper.vue'
import App from './components/App.vue'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)

Vue.component('image-helper', ImageHelper)

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCBTROq6LuvF_IE1r46-T4AeTSV-0d7my8',
		libraries: 'geometry',
	},
})

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

// Vue.use(VueGtag, {
// 	config: { id: "UA-175913832-1" }
// }, router);

new Vue({
	created() { 
		AOS.init({offset: 0, anchorPlacement: 'top-bottom'}) 
	},
	router,
	store,
	render: createElement => createElement(App)
}).$mount('#app');