<template>
  <div id="home">

    <introduction>
      <template v-slot:title>
        The magic is in the crimping.
      </template>
      <template v-slot:desc>
        <p>Joma Wool® is a one of a kind natural fibre. We source pure New Zealand wool and use a unique crimping process to improve its bulk and resilience by up to 50%. The result is a wool for mattresses that offers superior sleep performance. Mattress makers and customers all around the world choose Joma Wool® for the healthiest, most comfortable sleep possible.</p>
      </template>
    </introduction>

    <div class="fluid-container is-cover memory-wool">
      <image-helper :src="'memory-wool-2'" :position="'center center'"></image-helper>
      <div class="container grid md-col-2 gap-20 inner">
        <h2 class="white">Discover our new <span>Joma Memory Wool™.</span></h2>
        <div class="desc">
          <p>Achieves a perfectly cradling memory feel that’s 100% natural.</p>
          <router-link :to="{name: 'memory-wool'}">Learn more</router-link>
        </div>
      </div>
    </div>

    <module class="container ">
      <div v-for="(teaser,index) in teasers" :key="index" class="teaser grid sm-col-2 gap-30" data-aos="fade-up">
        <div class="is-cover three-two">
          <image-helper :src="teaser.image"></image-helper>
        </div>
        <div class="inner grid gap-20">
          <h2 class="charcoal">{{teaser.title}}</h2>
          <p>{{teaser.desc}}</p>
          <router-link :to="{name: teaser.link}">Find out more</router-link>
        </div>
      </div>
    </module>

    <module class="is-footer">
      <image-helper :src="'home-footer'" :position="'22% center'"></image-helper>
    </module>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";

  export default {
    components: { Introduction, Module },
    data() {
      return {
        teasers: [
          {
            title: 'For a naturally more restful sleep.',
            desc: 'Warm in winter, cool in summer, naturally moisture absorbent and allergy free. Discover the many sleep benefits of wool, nature’s original miracle fibre.',
            link: 'benefits',
            image: 'benefits'
          },
          {
            title: 'Environmentally-friendly New Zealand wool.',
            desc: 'All Joma Wool® is grown on New Zealand farms, where animals and the environment are treated with utmost care. A natural fibre, wool  supports a circular model of production.',
            link: 'sustainability',
            image: 'about'
          },
          {
            title: 'Products to suit your needs.',
            desc: 'We create a range of Joma Wool® products for use by mattress, bedding and other manufacturers around the world. View our product range.',
            link: 'products',
            image: 'wool-braid'
          },
          {
            title: 'Wool champions since 1933.',
            desc: 'Joma Wool® was developed by John Marshall & Co over 40 years ago. Every bale of genuine Joma Wool® is still created in our Christchurch factory today.',
            link: 'story',
            image: 'sheep-on-rock'
          },
        ]
      }
    }
  }
</script>