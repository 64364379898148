<template>
  <div id="terms">
    <module class="is-footer is-pattern">
      <div class="terms container grid gap-20">
        <h2 class="gold col sm-span-2-col">Terms and Conditions.</h2>
        <div class="grid gap-10">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget porta nibh. Nam tincidunt dui in nisl placerat, in porta velit luctus. Sed et leo laoreet risus malesuada faucibus at at lorem. Donec laoreet justo in varius gravida. In hac habitasse platea dictumst. Sed elementum eros turpis, et congue tortor vestibulum non. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam eget nisi porta, tempus tellus in, laoreet diam. Mauris porttitor lacus odio, at facilisis dolor vehicula non. Pellentesque eget massa sed massa congue mattis.</p>

          <p>Donec pellentesque consectetur urna et tristique. Praesent libero risus, pretium vitae vulputate ut, tempus eget ex. Nullam laoreet nunc tristique ullamcorper feugiat. Mauris tincidunt risus a orci fringilla blandit. Morbi ligula mi, ullamcorper quis placerat quis, lacinia et felis. Proin vehicula ut velit vitae maximus. Nunc tempus velit non purus placerat pharetra. Sed hendrerit felis enim, id maximus ex varius fermentum.</p>

          <p>Nam id quam et orci sollicitudin gravida quis vitae velit. Integer vehicula at metus eget sodales. Morbi eget metus a nunc rutrum hendrerit. Proin hendrerit felis eu feugiat suscipit. Duis commodo, magna et ornare viverra, massa eros venenatis augue, vitae porta quam metus vel ipsum. Donec rhoncus tellus vel mi ultrices aliquet. Nam faucibus aliquam libero, sit amet pretium orci aliquam nec. Praesent condimentum neque id venenatis facilisis. Curabitur eget finibus purus.</p>

          <p>Donec sagittis metus in tempus posuere. Cras id tortor eu lectus porta maximus euismod non est. Suspendisse potenti. Aliquam non elit vulputate, pellentesque sem non, iaculis tortor. Vivamus semper, purus nec ultrices elementum, justo ligula pellentesque ante, sed volutpat enim lacus ut est. Sed diam enim, varius non molestie sollicitudin, pulvinar ac velit. Donec hendrerit enim felis, non semper ipsum lobortis quis. Donec sollicitudin iaculis mauris, at molestie tellus lobortis rhoncus.</p>

          <p>Morbi sagittis tincidunt dui, et consequat lectus varius vitae. Nulla viverra convallis erat ac mollis. Vestibulum tempor tortor sit amet mi mollis aliquam. Aliquam sit amet mattis eros. Nunc dictum est vitae augue ullamcorper, sagittis maximus leo aliquet. Proin vel quam aliquam, lacinia risus nec, cursus lectus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
        </div>
      </div>
    </module>
  </div>
</template>

<script>
  import Module from "~/components/Module.vue";

  export default {
    components: {Module},
    data() {
      return {
      }
    }
  }
</script>