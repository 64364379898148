<template>
  <div id="contact">

    <introduction>
      <template v-slot:title>
        For sales enquiries or any other questions please contact us. We’re always happy to help.
      </template>
      <template v-slot:desc>
      </template>
    </introduction>

    <module class="has-border">
      <div class="details container grid sm-col-2 gap-30">
        <h2 class="gold">USA Orders & Enquiries</h2>
        <div class="inner grid gap-20">
          <ul>
            <li><span>Email</span><a href="mailto:contactus@jomawoolnz.com">contactus@jomawoolnz.com</a></li>
            <li><span>Phone</span><a href="tel:+16026335776">+1 (602) 633 - 5776</a></li>
          </ul>
        </div>
      </div>
    </module>

    <module class="has-border">
      <div class="details container grid sm-col-2 gap-30">
        <h2 class="gold">UK Orders & Enquiries</h2>
        <div class="inner grid gap-20">
          <ul>
            <li class="strong">Genady Fedotov</li>
            <li><span>Email</span><a href="mailto:genady@kofibres.com">genady@kofibres.com</a></li>
            <li><span>Phone</span><a href="tel:+447500735317">+44 7500 735317</a></li>
          </ul>
        </div>
      </div>
    </module>

    <module class="has-border">
      <div class="details container grid sm-col-2 gap-30">
        <h2 class="gold">Contact Details</h2>
        <div class="inner grid gap-20">
          <ul>
            <li><span>Email</span><a href="mailto:info@joma.co.nz">info@joma.co.nz</a></li>
            <li><span>Phone</span><a href="tel:+6433412004">+64 3 341 2004</a></li>
            <li><span>Fax</span>+64 3 341 6538</li>
          </ul>
          <ul>
            <li class="strong">Visit us</li>
            <li>63 Mandeville St, Riccarton, Christchurch, New Zealand 8011</li>
          </ul>
          <ul>
            <li class="strong">Mail to</li>
            <li>PO Box 8332, Christchurch, New Zealand 8440</li>
          </ul>
        </div>
      </div>
    </module>

    <module class="is-tan has-border" id="enquiry">
      <form-helper class="enquiry container grid gap-20" :submit="'Submit'">
        <h2 class="gold">Enquiry</h2>
        <div class="grid sm-col-2 gap-10">
          <div>
            <input type="text"
                   name="enquiryname"
                   placeholder="Name...">
            <input type="text"
                   name="enquiryemail"
                   placeholder="Email...">
            <input type="text"
                   name="enquiryphone"
                   placeholder="Phone...">
            <input type="hidden" name="url" value="">
          </div>
          <textarea name="enquirymessage" placeholder="Message..."></textarea>
        </div>
      </form-helper>
    </module>


    <module class="is-tan">
      <map-helper class="container" :markers="markers" :zoom="14" style="height: 400px;"></map-helper>
    </module>

    <module class="is-footer is-pattern has-border">
      <div class="details container grid sm-col-2 gap-30">
        <h2 class="gold">Market Insights</h2>
        <div class="inner grid gap-20">
          <a href="https://joma.nz/docs/current_market_report.pdf" target="_blank" class="market-report">
            <inline-svg :src="$getImageSrc('wool-report-icon')"></inline-svg>
            <div class="inner">
              <p class="strong">Market insights</p>
              <p>Download this week’s wool market report</p>
            </div>
          </a>
        </div>
      </div>
    </module>
   
  </div>
</template>


<script>
  import Introduction from '~/components/Introduction.vue'
  import FormHelper from '~/components/FormHelper'
  import MapHelper from "~/components/MapHelper.vue";
  import Module from "~/components/Module.vue";

  export default {
    components: { Introduction, FormHelper, MapHelper, Module },
    data(){
      return {
      }
    },
    computed: {
      markers() {
        return [{
          position: {
            lat: parseFloat(-43.532870), 
            lng: parseFloat(172.606100)
          }
        }]
      },
    }
  }
</script>