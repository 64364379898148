<template>
  <div id="benefits">

    <introduction>
      <template v-slot:title>
        Nature’s original miracle fibre
      </template>
      <template v-slot:desc>
        <p>For millennia humans have used woolen insulation to create comfortable, healthy and warm bedding. Now, ancient wisdom has now been verified by modern science, with a range of studies showing that wool does indeed help people sleep longer and deeper.</p>
        <p class="strong">Why does wool make such effective bedding?</p>
      </template>
    </introduction>

    <module class="is-footer is-pattern">
      <div class="benefits-wrapper grid sm-col-2 container">
        <h2 class="gold col sm-span-2-col">The many sleep benefits of wool</h2>
        <div class="benefit grid gap-5" v-for="(benefit,index) in benefits" :key="index" data-aos="fade-up">
          <inline-svg :src="$getImageSrc(benefit.image)"></inline-svg>
          <p class="strong big">{{benefit.title}}</p>
          <p>{{benefit.desc}}</p>
        </div>
      </div>
    </module>

  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";

  export default {
    components: {Introduction, Module},
    data() {
      return {
        benefits: [
          {
            image: 'benefit-icons/regulates-temperature.svg',
            title: 'Wool regulates our temperature ',
            desc: 'Wool is an exceptional insulator that keeps sleepers cool in summer and warm in winter. Temperature changes within wool are gradual, creating a stable microclimate which enables more restful sleep.'
          },
          {
            image: 'benefit-icons/soft-comfortable.svg',
            title: 'Wool is soft and comfortable',
            desc: 'Joma Wool® is extra springy and resilient due to its unique crimping process. This provides sleepers with a gentle, soft sleeping surface that maintains its loft over time.'
          },
          {
            image: 'benefit-icons/resilient-durable.svg',
            title: 'Wool is resilient and durable',
            desc: 'Did you know that a wool fibre can be bent over 20,000 times without breaking and still bounce back to its original shape? Wool’s natural helical shape and biochemical composition make it an incredibly strong and durable fibre.'
          },
          {
            image: 'benefit-icons/chemical-free.svg',
            title: 'Wool is chemical free',
            desc: 'Wool is a natural fibre, free from plastics or chemicals. Joma Wool® meets extremely strict quality standards and is certified by OEKO-TEX® to be free from APEO, NPEO, pesticides, carcinogenic colourants and heavy metals.'
          },
          {
            image: 'benefit-icons/non-allergenic.svg',
            title: 'Wool is non-allergenic',
            desc: 'Wool fibres are covered in scales which trap dust, mould spores and other harmful allergens, removing them from the air and preventing them from being ingested.'
          },
          {
            image: 'benefit-icons/absorbs-vapour-2.svg',
            title: 'Wool absorbs vapour but repels liquid',
            desc: 'Wool can absorb up to 30% of its weight in vapour without feeling damp, so sleepers are kept comfortable, not clammy. At the same time, wool’s water repellent outer surface causes liquid to bead up and run off. This make wool an ideal material for mattresses and bedding.'
          },
          {
            image: 'benefit-icons/odour-resistant.svg',
            title: 'Wool is odour resistant',
            desc: 'Because wool wicks away moisture, the growth of bacteria is inhibited. This makes wool naturally resistant to odours and ensures sleep products smell clean and fresh.'
          },
          {
            image: 'benefit-icons/resists-burning.svg',
            title: 'Wool resists burning',
            desc: 'The high keratin and moisture content of wool make it naturally fire resistant. Joma Wool® offers the safety and peace of mind of flame resistance without the need for harmful chemicals.'
          },
        ]
      }
    },
    computed: {
    }
  }
</script>


