<template>
  <div id="memory-wool">

    <introduction>
      <template v-slot:title>
        Sweet dreams are made of this
      </template>
      <template v-slot:desc>
        <p>Thanks to its unique crimping process and its resilience, Joma Memory Wool™ is the perfect component that provides that soft cradling “memory feel”. It naturally
        regulates body temperature during the night and creates a perfect micro-climate.</p>
      </template>
    </introduction>

    <module class="half-bottom">
      <div class="container">
        <div class="grid sm-col-2 gap-30">
          <h3 class="gold">No additives. Just nature.</h3>
          <div class="inner grid gap-20">
            <p>Joma Memory Wool™ can replace memory foam to create a natural comfort layer in your mattress.</p>
          </div>
        </div>
        <div class="vimeo" style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/844301219?h=5a2603ce08&autoplay=0&color=ef9b00&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
      </div>
    </module>

    <module class="half">
      <div class="container grid gap-20">
        <h3 class="gold">The natural choice</h3>
        <p>Joma Memory Wool™ is sourced with genuine care for animals and the environment. We use wool that is gently shorn from grass-fed crossbred sheep, which roam freely across some of New Zealand’s most remote farms.</p>
        <div class="icons grid sm-col-2 md-col-4 gap-20 sm-gap-30">
          <div v-for="icon in icons" class="icon grid">
            <inline-svg :src="$getImageSrc(`memory-wool-icons/${icon.icon}`)"></inline-svg>
            <p v-if="isXs" class="strong" v-html="stripBR(icon.title)"></p>
            <p v-else class="strong" v-html="icon.title"></p>
          </div>
        </div>
      </div>
    </module>

    <module class="half-top is-footer is-pattern">
      <div class="container">
        <h3 class="gold">Product certifications</h3>
        <div class="certs grid gap-40">
          <div v-for="cert in certs" class="cert grid">
            <div>
              <p class="strong">{{ cert.title }}</p>
              <p>{{ cert.content }}</p>
            </div>
            <inline-svg :src="$getImageSrc(cert.image)" :class="cert.image"></inline-svg>
          </div>
        </div>
      </div>

    </module>
   
  </div>
</template>

<script>
  import Introduction from "~/components/Introduction.vue";
  import Module from "~/components/Module.vue";
  import SustainabilityItem from "~/components/SustainabilityItem.vue";

  export default {
    components : { Introduction, Module, SustainabilityItem },

    data() {
      return {
       icons: [
        {
          icon: 'animal-welfare',
          title: 'Protecting <br>Animal <br>Welfare',
        },
        {
          icon: 'land-health',
          title: 'Preserving <br>Land <br>Health',
        },
        {
          icon: 'social-welfare',
          title: 'Protecting <br>Social <br>Welfare',
        },
        {
          icon: 'certification',
          title: 'Providing <br>Credible <br>Certification',
        }
      ],
      certs: [
        {
          title: 'Oritain',
          content: 'Joma Memory Wool™ is independently verified by Oritain to be of genuine New Zealand origin.',
          image: 'oritain-new'
        },
        {
          title: 'OEKO-TEX',
          content: 'Joma Memory Wool™ meets OEKO-TEX® Standard 100: certified free from harmful substances.',
          image: 'okeo-tex'
        },
        {
          title: 'New Zealand Farm Assurance Programme (NZFAP)',
          content: 'John Marshall & Co. is a Wool Member of NZFAP, providing assurance that the farms we source our wool from follow strict origin and welfare standards.',
          image: 'nzfap'
        }
      ]
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs';
      }
    },
    methods: {
      stripBR(str) {
        return str.replace(/<br>/g, '');
      }
    },
    mounted() {
      const script = document.createElement('script');
      script.src = "https://player.vimeo.com/api/player.js";
      document.body.appendChild(script);
    },
    created() {
   
    },
    destroyed() {
    }
  }
</script>

