<template>
    <form v-on:submit.prevent="submitForm" :class="state">
        <slot></slot>
        <input id="formSubmitButton" type="submit" :value="submitValue">
        <ul class="validation" v-if="Object.keys(errors).length">
            <li v-if="errors.length > 1">Errors.</li>
            <li v-else>Error.</li>
            <li v-for="error in errors">{{ error }}</li>
        </ul>
    </form>
</template>


<script>
  export default {
    props: {
      submit: {
        type: String,
        default: 'Send enquiry'
      },
      extra: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    data(){
      return {
        endpoint: '../process/',
        errors: {},
        state: ''
      }
    },
    computed: {
      submitValue() {
        if (this.state === 'processing') {
          return 'Processing...'
        } else if (this.state === 'success') {
          return 'Thanks, We\'ll be in touch.'
        } else {
          return this.submit
        }
      }
    },
    methods: {
      submitForm: function(e) {
        let formData = this.extra

        for(var el of e.target.elements){
          if(!el.name || el.type === 'submit') continue;
          formData[el.name] = el.value
        }

        var optionAxios = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        // console.log(formData)

        this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        this.axios.post(this.endpoint, formData, optionAxios)
        .then(res => {
          this.state = 'processing'
          this.errors = {}
          this.$emit('success', res)
          this.state = 'success'
        }).catch(error => {
          this.errors = error.response.data
          this.state = 'error'
        });
      },
    }
  }
</script>