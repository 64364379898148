<template>
  <div id="story">

    <introduction>
      <template v-slot:title>
        A longstanding<br> commitment to wool
        <img src="/assets/img/john-marshall.svg">
      </template>
      <template v-slot:desc>
        <p>Joma Wool® is developed, manufactured and exported around the world by John Marshall & Co.</p>
        <p>Founded in 1933, John Marshall & Co supplies a wide range of high quality New Zealand wools. Our products range from ultra fine merino to coarse crossbred wool, both scoured and greasy, along with slipe wools.</p>
        <p>Over our long history, synthetic developments have come and gone. Yet we continue to stand behind wool – nature’s incredible invention. There are simply no man-made products that come close to matching wool’s combination of natural attributes and dynamic performance.</p>
        <p>We are proud to share, enhance and promote wool’s place in the world.</p>
      </template>
    </introduction>

    <module class="container miracle-wrapper">
      <h2 class="gold">A natural miracle... with a twist</h2>
      <div class="grid">
        <div class="col is-cover sixteen-nine">
          <image-helper :src="'crimping-process'"></image-helper>
        </div>
        <div class="col">
          <p>What makes Joma Wool® so special? In the 1980’s, we discovered that by adding an extra step into the manufacturing process of mechanically crimping pure, natural New Zealand wool, the resulting wool gained unique beneficial properties.</p>
          <p>We found that the crimped wool gained an impressive 40–50% increase in volume, without adding any weight. It was also springier and more resilient, able to bounce back to its original shape even after constant pressure was applied.</p>
          <p>We named this unique product Joma Wool®, and spent years perfecting the process. With all the sleep benefits of wool plus additional properties that make it ideal for bedding, Joma Wool® is now found in premium mattresses and other products all around the world.</p>
          <p class="strong">Up to 50% greater volume | No added weight | Improved comfort & resilience.</p>
        </div>
      </div>
    </module>

    <module class="is-footer is-overlay is-tan">
      <image-helper :src="'story-bg'"></image-helper>
      <div class="values-wrapper container grid gap-20 sm-col-2">
        <h2 class="gold col sm-span-2-col">The values that guide us</h2>
        <div class="value" v-for="(value,index) in values" :key="index" data-aos="fade-up">
          <div class="grid gap-10">
            <h3 class="bronze">{{value.title}}</h3>
            <p>{{value.desc}}</p>
          </div>
        </div>
      </div>
    </module>
   
  </div>
</template>

<script>
  import Introduction from '~/components/Introduction.vue'
  import Module from "~/components/Module.vue";

  export default {
    components: {Introduction, Module},
    data() {
      return {
        values: [
          {
            title: 'We put nature first',
            desc: 'We care deeply for our natural world and want to keep it healthy and beautiful. Our wools are 100% biodegradable, free from plastics, sustainable and ethically sourced. '
          },
          {
            title: 'We promote health and wellbeing',
            desc: 'Human wellbeing means everything to us. Joma Wool® is a natural fibre that provides people with a myriad of proven sleep benefits, which in turn contributes to improved human health.'
          },
          {
            title: 'We are true to our word',
            desc: 'We have built a longstanding reputation for delivering to specification, on time, anywhere in the world. Joma Wool® products are backed by independent verification and quality standards, all of which we share openly.'
          },
          {
            title: 'We pursue innovation and performance',
            desc: 'Our aim is to promote the use of wool, nature’s remarkable fibre that synthetics can only imitate at best. We invest in research and innovation to enhance wool’s physical benefits and improve its economic and technical performance.'
          },
        ]
      }
    }
  }
</script>