<template>
  <main>
    <app-menu v-if="!errorPage"></app-menu>
    <div id="content" :style="{marginBottom : footerOffset}">
      <hero-image v-if="showHero" :data="heroImage[route]"></hero-image>
      <router-view :key="this.route" ></router-view>
    </div>
    <app-footer v-if="!errorPage"></app-footer>
  </main>
</template>

<script>
  import AppMenu from '~/components/AppMenu.vue'
  import HeroImage from "~/components/HeroImage.vue";
  import AppFooter from '~/components/AppFooter.vue'

  export default {
    components: {AppMenu, HeroImage, AppFooter},
    data() {
      return {
        heroImage: {
          home: {
            image: 'home.jpg',
            headline: 'The world’s best wool for mattresses.',
            color: 'charcoal',
            position: '50% 70%'
          },
          benefits: {
            image: 'benefits.jpg',
            headline: 'For a naturally better night’s sleep.',
            color: 'charcoal',
          },
          sustainability: {
            image: 'about.jpg',
            headline: 'Made in nature, returns to nature.',
            color: 'white',
            position: 'bottom center'
          },
          products: {
            image: 'products.jpg',
            headline: 'A wool to suit your needs.',
            color: 'white',
            position: ''
          },
          "memory-wool": {
            image: 'memory-wool.jpg',
            headline: 'Make new memories with Joma Memory Wool™',
            color: 'charcoal',
            position: '50% 85%'
          },
          story: {
            image: 'story.jpg',
            headline: 'Bringing premium New Zealand wool to the world.',
            color: 'white',
            position: 'center left'
          },
          contact: {
            image: 'contact.jpg',
            headline: 'Get in touch.',
            color: 'white',
          },
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      route() {
        return this.$route.name
      },
      errorPage(){
        return this.route === 'error'
      },
      showHero() {
        return !this.errorPage && this.route !== 'terms' && this.route !== 'privacy-policy'
      },
      footerOffset() {
        return this.$store.state.footerOffset
      }
    }
  }
</script>
