<template>
  <div id="introduction" data-aos="fade-up">
    <div class="container grid sm-col-2 gap-20">
      <h2 class="gold"><slot name="title"></slot></h2>
      <div class="desc" v-if="$slots.desc">
        <slot name="desc"></slot>
      </div>
    </div>
    
  </div>
</template>


<script>
  export default {
    data(){
      return {
      }
    }
  }
</script>