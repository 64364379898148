<template>
  <div id="legal">
    <module class="is-footer is-pattern">
      <div class="legal container grid gap-20">
        <h2 class="gold col sm-span-2-col">Privacy policy</h2>
        <div class="grid gap-10">

          <p class="strong">1. What is this privacy policy about?</p>
          <ol>
            <li>This Privacy Policy describes how John Marshall & Co Limited will treat the personal information that we collect about you through your use of our website, social media websites and pages and other communications with us.  This Privacy Policy applies to all interactions with us, subject to a more situation specific policy, disclosure or contract by or between us.</li>
            <li>This Privacy Policy explains to you how we collect, hold, use and disclose your personal information and how you can access and correct your personal information and what to do if you have a complaint.</li>
          </ol>


          <p class="strong">2. Collection of personal information</p>
          <ol>
            <li>What kinds of personal information do we collect and hold?</li>
            <li>The kinds of personal information that we collect and hold includes names, contact details, trade qualifications, government related identifiers, business details (e.g. tax file and inland revenue numbers, driver licence information),and other information you voluntarily provide to us, complaint details and other information for our business functions and activities.</li>
          </ol>


          <p class="strong">3. How do we collect and hold personal information?</p>
          <ol>
            <li>We usually collect personal information directly from the relevant individual.  We may collect your personal information when you communicate with us, such as when you:
              <ol>
                <li>make inquiries about us, our products and services or contact us for any other reason so that we can process, deal with and respond to your queries or other issues including any complaints; or  </li>
                <li>contact, register with, post to, like or follow any of our social media websites, pages, forums or blogs; or  </li>
                <li>use or register on our website; or  </li>
                <li>register a product you have purchased or subscribe to any of our services; or  </li>
                <li>register for and attend at events; or  </li>
                <li>make applications for prospective employment and contracting opportunities with us; or  </li>
                <li>subscribe to a newsletter, fill out a form or survey, enter or participate in any competitions or promotions.</li>
              </ol>
            </li>
            <li>Collection of personal information on our website or our social media pages will either be made clear from the context (e.g. request to complete information fields) or will state to the user that personal information is being collected.</li>
            <li>In addition we may collect information through click tracking (in relation to your use of our website including the content you access and any services you utilise) and through log files or cookies (as further detailed below).</li>
            <li>What happens if you do not provide your personal information to us?
              <ol>
                <li>If you do not provide personal information that we request in connection with our activities, we may not be able to provide some or all of our products or services to you or be able to respond and assist in the manner required.</li>
              </ol>
            </li>
          </ol>

          <p class="strong">4. Why do we collect, use, hold and disclose your personal information?  </p>
          <p>We collect, use, hold and disclose your personal information for the purposes of conducting our functions and business activities, including:</p>
          <ol>
            <li>names and contact details, to provide our products and services to our customers and information and all services relating to our products and services;</li>
            <li>names and contact details, to record information regarding products purchased from us and to provide further services to you, such as product warranties and guarantees, and repair or replacement of products;</li>
            <li>names, contact details, transaction and experience information such as information on your purchase transactions and our communications with you, to assist in providing better products and services to you by tailoring them to meet your needs, to keep our websites and other online presence relevant and of interest to users, for direct marketing including promotions, newsletters and competitions, to show you advertising and information that is most relevant to you and your interests, and to carry out analysis of market activity, market research and surveys;</li>
            <li>for verification of your identity for use of our website, to conduct address verification or credit checks for invoicing and billing purposes;</li>
            <li>to provide you with further information about us or other websites or goods or services offered by us or our related companies or which we consider may be of interest to you;</li>
            <li>names, contact details, educational information, membership of trade and professional associations, sensitive information and government related identifiers, for contracting with individuals and assessing individuals for current or future employment opportunities; and</li>
            <li>any other purpose which is stated to you at the time of collection or that you otherwise authorise.</li>
          </ol>

          <p class="strong">5. Do we use cookies or other web tracking systems?  </p>
          <p>We track traffic patterns throughout the URL (website) registered to us.</p>
          <p>We use "cookies" on our website. A "cookie" is a small amount of information which is transferred to the hard drive of your computer and which can identify your web browser, but not you. If you want, you can disable your web browser from accepting cookies. If you do so, you can still access our website, but not all services may be available.</p>
          <p>We may automatically collect general statistical information on our website about visitors to our website, such as IP addresses, browsers used, dates visited, pages visited and number of visitors. However, such information does not refer to individuals by name or their contact details. We use this data in aggregate to improve our website. We may provide such aggregated data to third parties, but in so doing, we do not provide identifiable personal information without the individual's consent.</p>
          <p>We use third party providers to provide us with web analytics services. These providers collect information on how individuals use our website. These providers may use cookies and other technology such as clear gifs or web beacons to obtain such information. This allows us to improve our website and our services.</p>

          <p class="strong">6. Third parties</p>
          <ol>
            <li>In some instances, we may collect personal information about individuals from third parties, such as wholesale and retail suppliers of home building, renovating and design related products, where individuals have made inquiries about our products to such third parties, credit agencies, recruitment agencies or from third party medical service providers (which provide medical reports and services to us).  In such circumstances we are relying on the third party to have obtained the individual’s consent.  We may also obtain personal information from publicly available resources.</li>
            <li>Certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</li>
            <li>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</li>
            <li>In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</li>
            <li>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</li>
          </ol>
          
          <p class="strong">7. Advertising and third party links</p>
          <p>Our website or other online presence may contain links to a variety of advertising and third party website sources. Some of these links may request or record information from users or use cookies or other methods to collect information from you. We have no control over the content or privacy policy practices of such sites, and encourage our users to review the privacy policies of such sites before engaging in any activity with them.</p>

          <p class="strong">8. Will we disclose personal information to anyone else?</p>
          <ol>
            <li>We may disclose personal information to:
              <ol>
                <li>our related companies and affiliates;</li>
                <li>third party contractors and providers of goods and services, such as courier and delivery companies, marketing and promotion companies, training and training certification agencies and business support services like storage and handling of documents and data, and information technology service providers;</li>
                <li>market research companies who undertake customer surveys for us;</li>
                <li>insurance companies;</li>
                <li>credit agencies</li>
                <li>professional service firms providing services to us, such as legal or accountancy services;
                <li>as required by law, such as to regulatory agencies; and</li>
                <li>any other recipient which is notified to you at the time of collection or that you otherwise authorise.</li>
                <li>if you violate our terms of service</li>
              </ol>
            </li>
            <li>We may also provide your information to others, if required or permitted by law, in accordance with the Principles and the Privacy Act.</li>
          </ol>
          
          <p class="strong">9. Do we disclose personal information overseas?</p>
          <ol>
            <li>We may disclose your personal information outside the country of collection.</li>
            <li>We share personal information outside the country of collection:</li>
            <li>where we have made a business decision to store our data with a trusted service provider who is in the business of providing data storage and processing services. These services commonly involve diverse geographic locations which may change from time to time, including as a result of change of provider, changes in data protection practices and processing efficiency. Where these services are used by us, it is not always practical for us to notify you of which country your personal information may be located in; </li>
            <li>for disclosures between our group companies. Our main business locations are in New Zealand.</li>
            <li>when our business which collected your personal information is in a different country to your location.</li>
          </ol>
          
          <p class="strong">10. Security</p>
          <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>

          <p class="strong">11. Age of consent</p>
          <p>By using this site, you represent that you are at least 18 years of age, and you have given us your consent to allow any of your minor dependents to use this site.</p>

          <p class="strong">12. Electronic messages</p>
          <ol>
            <li>If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your express consent, or provide you with an opportunity to say no.</li>
            <li>Upon consent, you agree to the usage, provision and acceptance of any information, including our latest news or notices, in electronic form in accordance with all applicable legislation.</li>
            <li>If you provide us with electronic contact details (including email or mobile phone number), you agree that we may send you commercial electronic messages.</li>
          </ol>

          <p class="strong">13. Unsubscribing from our marketing materials</p>
          <ol>
            <li>Unsubscribing from email lists
              <ul>
                <li>When we send commercial electronic messages (e.g. for marketing or promotional purposes), our message will contain instructions on how you may unsubscribe.</li>
              </ul>
            </li>
            <li>Unsubscribing from social media pages
              <ul>
                <li>Our social media pages provide instructions as to how you can unsubscribe from the relevant social media website or page</li>
              </ul>
            </li>
            <li>Unsubscribing from hard copy promotional materials
              <ul>
                <li>When we send you hard copy promotional materials, such materials will include instructions on how you may unsubscribe from receiving such materials.</li>
              </ul>
            </li>
          </ol>

          <p class="strong">14. Dealing with us anonymously</p>
          <ol>
            <li>You may deal with us on an anonymous basis when making inquiries through our website or social media pages, or when you make general inquiries by telephone and do not require a further response from us. However, we may need certain contact details from you to respond to inquiries.</li>
            <li>Generally, we will require your personal information in order to transact with you as noted previously in this Privacy Policy.</li>
          </ol>
          
          <p class="strong">15. Storage, Access, Correction and Removal of your Personal Information</p>
          <ol>
            <li>Any personal information that you provide to us will be collected and held by or on behalf of us.  For our physical address please refer here. Under applicable privacy law, you have rights of access to or correction of your personal information. You can do so at any time by contacting us by email at info@joma.nz., or to the 'Privacy Officer’ care of John Marshall & Co Limited, 63 Mandeville Street, Christchurch, NZ.</li>
            <li>Before we provide you with access to your personal information we may require some proof of identity.  We may charge a reasonable fee for giving access to your personal information if your request requires substantial effort on our part. </li> 
            <li>In accordance with the Privacy Act, if we do not agree to provide you with access to your personal information or to amend or annotate the information we hold about you, we will provide you with the basis for such decision and you make seek a review of it pursuant to your rights under the Privacy Act you may seek a review of our decision.</li>
          </ol>

          <p class="strong">16. Business Transitions</p>
          <p>In the event of a change in ownership of all or a portion of our business or the website, your user and other personal information may be transferred to the new owner so that the business or website (as applicable) can continue operations. In this event, your information would remain subject to this privacy policy.</p>

          <p class="strong">17. Defined terms in this Privacy Policy</p>
          <ol>
            <li>“Principles” is a reference to the privacy principles under the Privacy Act.</li>
            <li>“Privacy Act” is a reference to each of the Privacy Act 1993, and any other applicable privacy laws in New Zealand.</li>
          </ol>

          <p class="strong">18. Complaints</p>
            <ol>
              <li>You can send written complaints about a breach of the Principles or the Privacy Act in relation to your personal information by email to info@joma.nz or to the 'Privacy Officer’ care of John Marshall & Co Limited, 63 Mandeville Street, Christchurch, NZ.</li>
              <li>Complaints will be reviewed by our Privacy Officer and a response will usually be provided within 30 days of receipt of the complaint.</li>
              <li>If you believe that your complaint has not been satisfactorily addressed by us, after following the procedure set out above, you can make a complaint to the Office of the Privacy Commissioner (OPC).  </li>
            </ol>
          </p>


          <p class="strong">19. Amendments</p>
          <p>We may amend this Privacy Policy at any time. Amendments to this Privacy Policy will be posted on our website and will be effective when posted. Please check our Privacy Policy regularly for updates and amendments.</p>
          <p>Effective August 2020.</p>
          
        </div>
      </div>
    </module>
  </div>
</template>

<script>
  import Module from "~/components/Module.vue";

  export default {
    components: {Module},
    data() {
      return {
      }
    }
  }
</script>